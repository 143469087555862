import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import { UserAvatar } from 'src/components/user/user-avatar';
import { MessageDtoExtended } from 'src/services/messageApi';
import { getInitials } from 'src/utils/get-initials';
import { htmlToText } from 'src/utils/html-to-text';
import { classNames } from 'src/utils/tailwind-class-names';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';


interface MessageReplyToListItemProps {
    message: Pick<MessageDtoExtended, 'id' | 'from' | 'body'>;
    withAvatar?: boolean;
    withSenderName?: boolean;
    onCancel?: () => void;
    onClick?: () => void;
    variant: "WHITE" | "BLACK";
}

export const MessageReplyToListItem = (props: MessageReplyToListItemProps) => {
    return <Stack
        onClick={props.onClick}
        row
        space="sm" className={classNames('py-3 rounded pr-2 pl-3 w-full relative overflow-hidden', {
            'bg-white-neutral': props.variant === 'WHITE',
            'bg-black-10': props.variant === 'BLACK',
            'cursor-pointer': !!props.onClick
        })} items="center">
        <div className='bg-blue-80 w-[3px] h-[90%] rounded absolute left-0 top-0 ml-1 mt-0.5' />
        {props.withAvatar && <UserAvatar
            size="xxs"
            userId={props.message.from.id}
            role={props.message.from.roleDefault}
            avatarDocId={props.message.from.avatarDocId}
        >
            {getInitials(getUserDisplayName(props.message.from))}
        </UserAvatar>}
        <Stack space="sm" className='flex-1'>
            {props.withSenderName && <Text size="xs" variant="secondary">
                {getUserDisplayName(props.message.from)}
            </Text>}
            <Text size="sm" truncate className="flex-1 whitespace-break-spaces whitespace-pre-wrap break-all">
                {htmlToText(props.message.body)}
            </Text>
        </Stack>
        {props.onCancel && <Button
            onClick={props.onCancel}
            variant="ghost"
            className='p-1'
            size="custom">
            <Icon name="Cancel" width={16} height={16} strokeWidth={1.5} />
        </Button>}
    </Stack>
}