
import SubjectSelect from 'components/dist/organisms/SubjectSelect';
import { useGetRecipientsUsers } from 'src/hooks/messages/use-get-recipients-list';
import { isRoleABorrower } from 'src/utils/user/is-role-a-borrower';

import { useInlineComposeMessageForm } from './inline-compose-message-form.state';

interface Props extends Pick<ReturnType<typeof useInlineComposeMessageForm>, 'setValue' | 'threads' | 'watch' | 'loanId' | 'recipients'> { }

export const InlineComposeMessageFormSubject = ({ threads = [], ...props }: Props) => {
    const { users } = useGetRecipientsUsers({
        loanId: props.loanId,
        type: 'ALL'
    });

    const onChange = (value) => {
        props.setValue('subject', value.label, {
            shouldDirty: true,
            shouldValidate: true
        })
        if (value.type === "EXISTING") {
            const thread = threads.find(thread => thread.id === value.value);
            if (thread?.lendingTeam) {
                // if thread is for lending team we need to clear all borrower recipients
                const lenderRecipients = props.recipients.filter(recipient => users.find(user => user.id === recipient && !isRoleABorrower(user.roleDefault)));
                props.setValue('recipients', lenderRecipients, {
                    shouldDirty: true,
                    shouldValidate: true
                })
            }
            props.setValue('messageThreadId', value.value, {
                shouldDirty: true,
                shouldValidate: true
            })
        }
    }

    const threadsOptions = threads
        // filter out direct threads
        .filter(thread => !["DIRECT_NO_SUBJECT"].includes(thread.messageThreadCategory))
        // filter out threads that are inactive
        .filter(thread => !thread.inactive)
        .map(thread => ({
            label: thread.title,
            value: thread.id,
            type: "EXISTING" as "EXISTING" | "NEW",
            private: thread.locked
        }));
    const subject = props.watch('subject');

    if (subject && !threadsOptions.find(thread => thread.label === subject)) {
        threadsOptions.unshift({
            label: subject,
            value: subject,
            type: "NEW" as "EXISTING" | "NEW",
            private: false
        });
    }


    return <SubjectSelect
        placeholder='Enter Subject'
        onChange={onChange}
        value={subject ? { label: subject, value: subject, type: "NEW", private: false } : null}
        options={threadsOptions}
    />
}
