import { LoanRoleDto } from "src/backend";
import { useGetLoanByIdQuery } from "src/services/loanApi";
import { createRecipientList } from "src/utils/loan/create-recipient-list";
import { isRoleABorrower } from "src/utils/user/is-role-a-borrower";

import { useLenderEmployees } from "../use-lender-employees";
import { useUser } from "../use-user";

interface GetRecipientsUsersProps {
    loanId: string;
    type: 'ALL' | 'BORROWERS' | 'LENDERS';
}

export const useGetRecipientsUsers = (props: GetRecipientsUsersProps) => {
    const { data: loan = { loanRoles: [] as LoanRoleDto[], lender: { id: null } } } = useGetLoanByIdQuery(props.loanId, {
        skip: !props.loanId
    });
    const loggedInUser = useUser();

    const { employees = [] } = useLenderEmployees({
        lenderId: loan.lender.id,
        skip: !loggedInUser.isLender || !props.loanId
    });

    const { loanRoles } = loan;
    const typeLoanRoles: LoanRoleDto[] = []
    if (props.type === 'ALL') {
        typeLoanRoles.push(...loanRoles);
    } else if (props.type === 'BORROWERS') {
        typeLoanRoles.push(...loanRoles.filter(({ role }) => isRoleABorrower(role)));
    } else if (props.type === 'LENDERS') {
        typeLoanRoles.push(...loanRoles.filter(({ role }) => !isRoleABorrower(role)));
    }
    // combine loan roles users and employees and logged in user
    // and filter out the any duplicate users
    const users = [
        ...typeLoanRoles.map(({ user }) => user),
        ...employees,
        loggedInUser.user
    ].filter((user, index, self) => self.findIndex(({ id }) => id === user.id) === index);

    return {
        users,
        recipientsOptions: createRecipientList(typeLoanRoles, employees, loggedInUser.user)
    } as const;
};