import { j as e } from "./jsx-runtime-B6kdoens.js";
import { B as i } from "./Button-ChOUYHf0.js";
import { D as t } from "./Dialog.component-9plNKAVm.js";
import { I as a } from "./Icon-CK-T4e-h.js";
import { S as n } from "./Stack-C1p7gIIp.js";
import { T as r } from "./Text-RuGJaJiA.js";
const h = (s) => /* @__PURE__ */ e.jsx(
  t,
  {
    open: !0,
    onOpenChange: s.onOpenChange,
    children: /* @__PURE__ */ e.jsx(t.Content, { className: "w-full sm:max-w-md", children: /* @__PURE__ */ e.jsxs(n, { className: "px-3 bg-white w-full mx-auto pb-6 pt-2 rounded-md  text-center", items: "center", justify: "center", space: "xl", children: [
      /* @__PURE__ */ e.jsx(n, { justify: "end", row: !0, className: "w-full", children: /* @__PURE__ */ e.jsx(t.Close, { asChild: !0, children: /* @__PURE__ */ e.jsx(
        i,
        {
          circle: !0,
          size: "sm",
          variant: "secondary",
          children: /* @__PURE__ */ e.jsx(a, { name: "Cancel", width: 24, height: 24, strokeWidth: 1.5 })
        }
      ) }) }),
      /* @__PURE__ */ e.jsx(a, { name: "BigExclamationDestructive", className: "text-black-10", width: 64, height: 64 }),
      s.fileName && /* @__PURE__ */ e.jsxs(r, { size: "sm", className: "px-10", children: [
        "You do not have access to view ",
        s.fileName,
        "."
      ] }),
      !s.fileName && /* @__PURE__ */ e.jsx(r, { size: "sm", className: "px-10", children: "You do not have access to view this item." }),
      s.onAskMyLenderClick && /* @__PURE__ */ e.jsx(
        i,
        {
          onClick: s.onAskMyLenderClick,
          size: "sm",
          variant: "link",
          className: "underline cursor-pointer underline-offset-2",
          children: "Ask My Lender"
        }
      )
    ] }) })
  }
);
export {
  h as default
};
